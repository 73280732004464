import React from "react"
import { navigate } from "gatsby"
import { postVote } from "../../../../api"
import LayoutCourse from "../../../../layouts/course"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"

import Bullet from "../../../../components/bullet"
import BulletList from "../../../../components/bullet-list"
import Paragraph from "../../../../components/paragraph"
import CourseFooterBool from "../../../../components/course-footer-bool"
import Heading from "../../../../components/heading"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import CourseSummary from "../../../../components/course-summary"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })
  const progress = getCourseProgress({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Hat dir der Kurs gefallen?"
          onSubmit={async (positive) => {
            try {
              await postVote({
                title: "hoelderlins-reisen",
                upvoted: positive,
              })
            } catch (error) {}
            navigate("/kurse/hoelderlins-reisen/feedback", {
              state: { positive },
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Eine Reiseerinnerung im Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Zusammenfassung
        </Heading>
        <CourseSummary {...progress} />
        <Paragraph>
          In diesem Kurs bist du Hölderlin bis nach Heidelberg gefolgt.
        </Paragraph>
        <BulletList size={[3, 3, 4, 5]}>
          <Bullet>
            Dabei hast du seine Handschrift kennengelernt und entziffert.
          </Bullet>
          <Bullet>
            Du hast dir eine Fotografie der Stadt angesehen und einige Elemente
            daraus in Hölderlins Gedicht ›Heidelberg‹ wiedergefunden.
          </Bullet>
          <Bullet>
            Beim Zählen der Silben hast du festgestellt, dass Hölderlin aus den
            Wörtern seines Gedichts ein Musikstück entwickelt hat. Dafür hat er
            zum Teil auch neue, zusammengesetzte Wörter erfunden.
          </Bullet>
          <Bullet>
            Du hast die Silbenanzahl der Zeilen verglichen. Dabei hast du
            bemerkt, dass Hölderlin die Strophen seines Gedichts alle nach
            demselben Muster angelegt hat.
          </Bullet>
          <Bullet>
            Zuletzt hast du das Gedicht selbst laut vorgetragen und dachte auf
            den Rhythmus und die Betonungen der Wörter geachtet.
          </Bullet>
        </BulletList>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
